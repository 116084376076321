/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import "@fortawesome/fontawesome-svg-core/styles.css";
import { config as faConfig } from "@fortawesome/fontawesome-svg-core";
import { AnimatePresence } from "framer-motion";
import { useStaticRendering } from "mobx-react";
import App from "next/app";
import Head from "next/head";

import Master from "../components/master";
import { CustomShopifyContext } from "../stores/shopify";
import "../themes/default.css";
import "../utils/theme";

// Skips auto adding FontAwesome styles because they're imported above. This is
// the recommended way of using FontAwesome with Next.
faConfig.autoAddCss = false;

// MobX SSR bugfix
useStaticRendering(typeof window === "undefined");

class CustomApp extends App {
  public render() {
    const {
      Component,
      pageProps,
      router: {
        pathname,
      },
    } = this.props;

    // Make sure that these theme colors are in sync with the ones from the
    // `tailwind.config.js` file.
    const themeColor = this.getThemeColor(pathname);

    // IMPORTANT: meta elements must be placed before link elements for
    // W3C validator to pass.
    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta
            content="yes"
            name="apple-mobile-web-app-capable"
          />
          <meta
            content="black"
            name="apple-mobile-web-app-status-bar-style"
          />
          <meta
            content="Lasting Smiles"
            name="apple-mobile-web-app-title"
          />
          <meta
            content={themeColor}
            name="theme-color"
          />
          <meta
            content="initial-scale=1, minimum-scale=1, width=device-width"
            name="viewport"
          />
          <link
            href={require("../public/chromiums/apple-touch-192x192-icon.png") as string}
            rel="apple-touch-icon"
            sizes="192x192"
          />
          <link
            href={require("../public/chromiums/apple-touch-startup-1242x2688-image.png") as string}
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          <link
            href={require("../public/chromiums/apple-touch-startup-828x1792-image.png") as string}
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
            rel="apple-touch-startup-image"
          />
          <link
            href={require("../public/chromiums/apple-touch-startup-1125x2436-image.png") as string}
            media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          <link
            href={require("../public/chromiums/apple-touch-startup-1242x2208-image.png") as string}
            media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          <link
            href={require("../public/chromiums/apple-touch-startup-750x1334-image.png") as string}
            media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
            rel="apple-touch-startup-image"
          />
          <link
            href={require("../public/chromiums/fav-48x48-icon.png") as string}
            rel="shortcut icon"
            sizes="48x48"
          />
          <link
            href={require("../public/chromiums/fav-96x96-icon.png") as string}
            rel="shortcut icon"
            sizes="96x96"
          />
          <link
            href="/manifest.json"
            rel="manifest"
          />
          <link
            href="https://api.lastingsmiles.org/"
            rel="preconnect"
          />
          <link
            href="https://shop.lastingsmiles.org/"
            rel="preconnect"
          />
          <link
            href="https://www.google-analytics.com/"
            rel="preconnect"
          />
          <script dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1870813400354728');
            fbq('track', 'PageView');
            `}}
          />
          <noscript>
            <img height="1" width="1" style={{display:"none"}}
              src="https://www.facebook.com/tr?id=1870813400354728&ev=PageView&noscript=1"
            />
          </noscript>
        </Head>
        <CustomShopifyContext>
          <Master>
            <AnimatePresence exitBeforeEnter={true}>
              <Component {...pageProps} />
            </AnimatePresence>
          </Master>
        </CustomShopifyContext>
      </>
    );
  }

  private getThemeColor(pathname: string) {
    switch (pathname) {
      case "/products/passionfruit-mango-lip-balm":
        return "#F6D16E";

      case "/products/peach-blossom-lip-balm":
        return "#E6A076";

      case "/products/peppermint-creme-lip-balm":
        return "#ACDAE7";

      case "/products/vanilla-bean-organic-lip-balm":
        return "#FFF2BE";

      case "/products/variety-lip-balm":
        return "#ACCF8C";

      case "/products/very-cherry-lip-balm":
        return "#E795AB";

      default:
        return "#FFF9E9";
    }
  }
}

export default CustomApp;
