/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrowPathIcon,
  GiftIcon,
} from "@heroicons/react/24/solid";
import {
  AnimatePresence,
  motion,
} from "framer-motion";
import { useObserver } from "mobx-react";
import {
  ChangeEvent,
  useContext,
  useState,
} from "react";
import ShopifyBuy from "shopify-buy";

import OrganizationKind from "../../enums/organization-kind";
import {
  getOrganizationSlug,
  hideShopifyModal,
  IShopifyStore,
  ShopifyContext,
  updateOrganization,
  updateShopifyCart,
  checkoutRedirect,
} from "../../stores/shopify";

const ShopifyModal = () => {
  const shopifyStore: IShopifyStore =
    useContext(ShopifyContext) as IShopifyStore;

  const currencyFormat = new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
    style: "currency",
  });

  return useObserver(() => {
    const {
      lineItems,
      modalVisible,
      selectedOrganization,
      giftNote: savedGiftNote,
    } = shopifyStore;

    const decrement = (id: string | number, quantity: number) => {
      updateShopifyCart(id as string, quantity - 1);
    };

    const increment = (id: string | number, quantity: number) => {
      updateShopifyCart(id as string, quantity + 1);
    };

    const remove = (id: string | number) => {
      updateShopifyCart(id as string, 0);
    };

    const handleOrganizationChange = (event: ChangeEvent<HTMLSelectElement>) => {
      switch (event.target.value) {
        case getOrganizationSlug(OrganizationKind.BestFriendsAnimalSociety):
          updateOrganization(OrganizationKind.BestFriendsAnimalSociety);
          return;
        case getOrganizationSlug(OrganizationKind.ChildrensCraniofacialAssociation):
          updateOrganization(OrganizationKind.ChildrensCraniofacialAssociation);
          return;
        case getOrganizationSlug(OrganizationKind.ChildrensDefenseFund):
          updateOrganization(OrganizationKind.ChildrensDefenseFund);
          return;
        case getOrganizationSlug(OrganizationKind.GirlsInc):
          updateOrganization(OrganizationKind.GirlsInc);
          return;
        case getOrganizationSlug(OrganizationKind.GirlsOnTheRun):
          updateOrganization(OrganizationKind.GirlsOnTheRun);
          return;
        case getOrganizationSlug(OrganizationKind.Impact100Global):
          updateOrganization(OrganizationKind.Impact100Global);
          return;
        case getOrganizationSlug(OrganizationKind.MealsOnWheelsAmerica):
          updateOrganization(OrganizationKind.MealsOnWheelsAmerica);
          return;
        case getOrganizationSlug(OrganizationKind.NationalAllianceOnMentalIllness):
          updateOrganization(OrganizationKind.NationalAllianceOnMentalIllness);
          return;
        case getOrganizationSlug(OrganizationKind.RonaldMcDonaldHouseCharitiesBayArea):
          updateOrganization(OrganizationKind.RonaldMcDonaldHouseCharitiesBayArea);
          return;
        case getOrganizationSlug(OrganizationKind.WomenForWater):
          updateOrganization(OrganizationKind.WomenForWater);
          return;
        case getOrganizationSlug(OrganizationKind.WorldVision):
          updateOrganization(OrganizationKind.WorldVision);
          return;
        case getOrganizationSlug(OrganizationKind.YoungAudiencesArtsForLearning):
          updateOrganization(OrganizationKind.YoungAudiencesArtsForLearning);
          return;
      }
    }

    const [loading, setLoading] = useState(false);
    const [giftNote, setGiftNote] = useState(savedGiftNote);

    const handleGiftNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setGiftNote(event.target.value);
    };

    const handleCheckoutClick = () => {
      setLoading(true);
      checkoutRedirect(giftNote);
    };

    return (
      <AnimatePresence>
        {modalVisible && lineItems.length > 0 && (
          <motion.div
            animate={{ opacity: 1 }}
            className="ls-overlay"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <motion.div
              animate={{
                opacity: 1,
                y: 0,
              }}
              className="bg-default fixed h-full sm:h-auto left-0 sm:left-auto sm:border sm:border-black sm:max-w-screen-sm sm:static overflow-y-auto p-4 top-0 sm:top-auto w-full"
              exit={{
                opacity: 0,
                y: 20,
              }}
              initial={{
                opacity: 0,
                y: 20,
              }}
              transition={{ delay: 0.5 }}
            >
              <div className="relative">
                <FontAwesomeIcon
                  className="absolute cursor-pointer right-0 top-0"
                  fixedWidth={true}
                  icon={faTimes}
                  onClick={() => { hideShopifyModal(); }}
                  size="lg"
                />
                <h2 className="font-serif text-3xl">
                  Your Cart
                </h2>
                <ul>
                  {lineItems.map((value: ShopifyBuy.LineItem, index: number) => (
                    <li key={index}>
                      <span className="font-serif mr-10 text-xl">{value.title}</span>
                      <br />
                      <button
                        aria-label="Increment Quantity"
                        className="border border-gray-400 font-semibold font-serif hover:border-black hover:text-black inline-block outline-none px-4 py-2 rounded-none text-sm text-gray-600"
                        onClick={() => { decrement(value.id, value.quantity); }}
                      >
                        -
                      </button>
                      <span className="font-semibold font-serif px-5 text-sm">{value.quantity}</span>
                      <button
                        aria-label="Decrement Quantity"
                        className="border border-gray-400 font-semibold font-serif hover:border-black hover:text-black inline-block outline-none px-4 py-2 rounded-none text-sm text-gray-600"
                        onClick={() => { increment(value.id, value.quantity); }}
                      >
                        +
                      </button>
                      <span className="px-5 text-sm">
                        <a
                          className="cursor-pointer text-gray-600"
                          onClick={() => { remove(value.id); }}
                        >
                          Remove
                        </a>
                      </span>
                    </li>
                  ))}
                </ul>
                <p className="font-serif my-2 text-xl">
                  Total:
                  <span>{" "}</span>
                  {currencyFormat.format(shopifyStore.subtotalPrice)}
                </p>
                <div className="text-center">
                  <a
                    className="border border-black bg-black cursor-pointer font-semibold font-serif hover:bg-default hover:text-black inline-block outline-none px-6 py-4 rounded-none uppercase text-lg text-white"
                    onClick={handleCheckoutClick}
                  >
                    <span>Checkout</span>
                    {loading && (
                      <>
                        {" "}
                        <ArrowPathIcon className="animate-spin w-5 h-5 inline-block" />
                      </>
                    )}
                  </a>
                </div>
                <p className="my-2">
                  We donate 20% of your e-commerce purchase to the cause you select.
                  Please select which area of impact your purchase is directed to:
                </p>
                <select
                  className="w-full"
                  name="organization"
                  onChange={handleOrganizationChange}
                  value={getOrganizationSlug(selectedOrganization)}
                >
                  <option value={getOrganizationSlug(OrganizationKind.BestFriendsAnimalSociety)}>Best Friends Animal Society</option>
                  <option value={getOrganizationSlug(OrganizationKind.ChildrensCraniofacialAssociation)}>Children's Craniofacial Association</option>
                  <option value={getOrganizationSlug(OrganizationKind.ChildrensDefenseFund)}>Children's Defense Fund</option>
                  <option value={getOrganizationSlug(OrganizationKind.GirlsInc)}>Girls Inc.</option>
                  <option value={getOrganizationSlug(OrganizationKind.GirlsOnTheRun)}>Girls on the Run</option>
                  <option value={getOrganizationSlug(OrganizationKind.Impact100Global)}>Impact100 Global</option>
                  <option value={getOrganizationSlug(OrganizationKind.MealsOnWheelsAmerica)}>Meals on Wheels America</option>
                  <option value={getOrganizationSlug(OrganizationKind.NationalAllianceOnMentalIllness)}>National Alliance on Mental Illness</option>
                  <option value={getOrganizationSlug(OrganizationKind.RonaldMcDonaldHouseCharitiesBayArea)}>Ronald McDonald House Charities Bay Area</option>
                  <option value={getOrganizationSlug(OrganizationKind.WomenForWater)}>women for water</option>
                  <option value={getOrganizationSlug(OrganizationKind.WorldVision)}>World Vision</option>
                  <option value={getOrganizationSlug(OrganizationKind.YoungAudiencesArtsForLearning)}>Young Audiences Arts for Learning</option>
                </select>
                <hr className="my-5" />
                <p className="mb-2">
                  <GiftIcon className="h-5 inline-block w-5" />
                  {" "}
                  Is this a gift? If so, please write your gift note below. We will beautifully handwrite it on your behalf:
                </p>
                <textarea
                  className="w-full"
                  name="giftnote"
                  onChange={handleGiftNoteChange}
                  placeholder="Enter your gift note here"
                  value={giftNote}
                  rows={4}
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  });
};

export default ShopifyModal;
