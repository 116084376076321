/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import { useRouter } from "next/router";

const Banner = () => {
  const { pathname } = useRouter();

  const themeClassName = getThemeClassName(pathname);

  return (
    <p className={"px-2 py-1 sm:px-5 text-center " + themeClassName}>
      20% of all e-commerce sales are donated to the cause of your choice.
    </p>
  );
};

const getThemeClassName = (pathname: string) => {
  switch (pathname) {
    case "/products/balance-aromatherapy-cause-candle":
      return "bg-balance-300";
    case "/products/bliss-aromatherapy-cause-candle":
      return "bg-bliss-300";
    case "/products/calm-aromatherapy-cause-candle":
      return "bg-calm-300";
    case "/products/focus-aromatherapy-cause-candle":
      return "bg-focus-300";
    case "/products/sea-salt-citrus-aromatherapy-cause-candle":
      return "bg-sea-salt-citrus-300";

    case "/products/passionfruit-mango-lip-balm":
      return "bg-passionfruit-mango-300";
    case "/products/peach-blossom-lip-balm":
      return "bg-peach-blossom-300";
    case "/products/peppermint-creme-lip-balm":
      return "bg-peppermint-creme-300";
    case "/products/strawberry-creme-organic-lip-balm":
      return "bg-strawberry-creme-300";
    case "/products/vanilla-bean-organic-lip-balm":
      return "bg-vanilla-bean-300";
    case "/products/variety-lip-balm":
      return "bg-variety-300";
    case "/products/very-cherry-lip-balm":
      return "bg-very-cherry-300";

    case "/products/polish-exfoliant-lip-scrub":
      return "bg-polish-exfoliant-300";

    default:
      return "bg-water";
  }
}

export default Banner;
